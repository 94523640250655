import { CATEGORY_FRAGMENT } from "./Category";
import { COMMENT_FRAGMENT } from "./Chat";
import { COLLECTION_FRAGMENT } from "./Collection";
import { PAGE_INFO_FRAGMENT, TAXED_MONEY_FRAGMENT } from "./Common";
import {
  PRODUCT_FRAGMENT,
  PRODUCT_VARIANT_FRAGMENT,
  SELLING_CONDITIONS_FRAGMENT,
} from "./Product";
import { SELLER_FRAGMENT } from "./Seller";
import { USER_FRAGMENT } from "./User";

export const VIDEO_FRAGMENT = `
  fragment video on VideoType {
    id
    globalId
    title
    added
    resultFile
    isHorizontal
    videoStartTime
    status
    isLiked
    duration
    comments(latest: 1000) @include(if: $withComments) {
      ...comment
    }
    author {
      ...user
    }
    videoStats {
      likes
      comments
      views
    }
    videoImage {
      url
      alt
    }
    popups {
      title
      titleUrl
      text
      linkUrl
      linkLabel
      image
      discountCode
      order
      timeOfDisplaying
      videoDuration
      sent
      sentBy {
        id
        email
      }
      id
    }
    activePopup {
      title
      titleUrl
      text
      linkUrl
      linkLabel
      image
      discountCode
      order
      timeOfDisplaying
      videoDuration
      sent
      sentBy {
        id
        email
      }
      id          
    }
    videoProducts @include(if: $withProducts) {
      product {
        ...product
      }
      isVisible
      startTime
      endTime
    }
    visibleProducts {
      id
      name
    }
  }
`;

export const VIDEOS = `
  query videos($first: Int, $after: String, $filter: VideoFilterInput, $withProducts: Boolean!, $language: LanguageCodeEnum!, $withComments: Boolean = false) {
    videos(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...video
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${TAXED_MONEY_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${SELLER_FRAGMENT}
  ${SELLING_CONDITIONS_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${USER_FRAGMENT}
  ${COMMENT_FRAGMENT}
`;

export const VIDEO = `
  query videoByGlobalId($id: ID, $withProducts: Boolean!, $language: LanguageCodeEnum!, $withComments: Boolean = true) {
    videoByGlobalId(
      globalId: $id
    ) {
      ...video
    }
  }
  ${VIDEO_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${TAXED_MONEY_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${SELLER_FRAGMENT}
  ${SELLING_CONDITIONS_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${USER_FRAGMENT}
  ${COMMENT_FRAGMENT}
`;

export const LIKE_VIDEO = `
  mutation likeVideo($id: ID!) {
    likeVideo(id: $id) {
      video {
        id
        isLiked
      }
    }
  }
`;

export const DISLIKE_VIDEO = `
  mutation dislikeVideo($id: ID!) {
    dislikeVideo(id: $id) {
      video {
        id
        isLiked
      }
    }
  }
`;

export const VIDEO_VIEW_ADD = `
  mutation videoViewAdd($videoId: ID!) {
    videoViewAdd(video: $videoId) {
      video {
        videoStats {
          views
        }
      }
    }
  }
`;

export const SELLERS = `
query publicSellerProfile($id: ID!) {
  publicSellerProfile(id: $id) {
    id
    name
    latestPublicVideo {
      globalId
      id
      title
      isLiked
      isHorizontal
      resultFile
      status
      videoStartTime
      videoImage {
        alt
        url
      }
      visibleProducts {
        id
        name
        description
        slug
      }
      videoStats {
        views
      }
    }
  }
}
`;